<template>
  <div>
    <div v-show="isSwiperVisible" class="swiper-container">
      <swiper
        :modules="[Controller, Scrollbar]"
        :slidesPerView="6"
        :spaceBetween="20"
        :scrollbar="{ draggable: true }"
        :breakpoints="{
          320: {
            slidesPerView: !mcolumn ? 2 : mcolumn,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: !mcolumn ? 2 : mcolumn,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: !mcolumn ? 2 : mcolumn,
            spaceBetween: 20,
          },
          1000: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
        }"
      >
        <swiper-slide v-for="(item, index) in items" :key="index">
          <slot :item="item"></slot>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, onMounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Controller, Navigation, Scrollbar } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const isSwiperVisible = ref(false);

onMounted(() => {
  // Swiper componenti yüklendikten sonra görünür hale getir
  isSwiperVisible.value = true;
});

const swiper = Swiper;

defineProps<{
  items: any;
  mcolumn: number | undefined;
}>();
</script>

<style></style>
